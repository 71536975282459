import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetPadronProvisorio } from "../../services/api/padronProvisorio";
import SearchPadronProvisorio from "./Forms/SearchPadronProvisorio";
import PrintCardButton from "./PrintCardProvisorioButton";
import axios, { AxiosError } from "axios";
import { useGetEleccionById } from "../../services/api/elecciones";
import { SimpleCard } from "stj-components";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  Grid,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PadronProvisorioInterface } from "../../interfaces/PadronProvisorioInterface";

const ListPadronProvisorio = () => {
  const [mostrarBusqueda, setMostrarBusqueda] = useState(true);
  const [padronProvisorio, setPadronProvisorio] =
    useState<PadronProvisorioInterface>();

  const { mutateAsync, error, isError, isLoading } = useGetPadronProvisorio();

  const [eleccionId, setEleccionId] = useState("0");
  const { data: eleccion } = useGetEleccionById(parseInt(eleccionId), null);

  const handleOnSubmit = async (values: any, refetch: any) => {
    const { eleccion_id } = values;

    if (eleccion_id) {
      setEleccionId(eleccion_id);
    } else {
      setEleccionId("0");
    }

    try {
      setMostrarBusqueda(false);
      await mutateAsync(values, {
        onSettled: (data) => {
          setPadronProvisorio(data?.data);
          refetch();
        },
      });
    } catch (error) {
      refetch();
    }
  };

  useEffect(() => {
    if (isError) {
      if (axios.isAxiosError(error)) {
        const myerror = error as AxiosError<any, any>;
        toast.error(`${myerror.response?.data.message}`);
      }
    }
  }, [isError, error]);

  return (
    <>
      <SimpleCard title="Consulta Padrón Provisorio">
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Alert severity="warning">
              <AlertTitle>Reclamos:</AlertTitle>
              {`Ante cualquier reclamo enviar un correo a `}
              <a
                href="mailto:juzelectoral@justierradelfuego.gov.ar"
                target="_blank"
                style={{ textDecoration: "none", color: "#1363DF" }}
              >
                juzelectoral@justierradelfuego.gov.ar
              </a>
            </Alert>
          </Grid>

          {mostrarBusqueda ? (
            <>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <SearchPadronProvisorio handleOnSubmit={handleOnSubmit} />
              </Grid>
              <Alert severity="info">
                Rellene los campos y presione BUSCAR
              </Alert>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <PrintCardButton
                  padronProvisorio={padronProvisorio}
                  eleccion={eleccion}
                  isLoading={isLoading}
                  error={error}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  onClick={() => setMostrarBusqueda(true)}
                >
                  Hacer otra consulta
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </SimpleCard>
    </>
  );
};

export default ListPadronProvisorio;
