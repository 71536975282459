import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/HomePage/HomePage";
import { LayoutInformativo } from "stj-components";
import { QueryClient, QueryCache, QueryClientProvider } from "react-query";
import toast, { Toaster } from "react-hot-toast";
import NoMatch from "./pages/NoMatch";
import { appComponents } from "./appComponents";
import EmailIcon from "@mui/icons-material/Email";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // background update failure
    onError: (error, query) => {
      if (query.state.data !== undefined && error instanceof Error) {
        toast.error(
          `${error.message} error buscando los datos, por favor intente en unos momentos`
        );
      }
    },
  }),
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Box>
        {/* Las routes se anidan unas dentro de otras.
          Los paths de rutas anidadas se construyen con los paths de los routes padres.
          Y los elementos de route anidados se renderizan
          dentro de los elementos de routes padre. */}
        <Routes>
          <Route
            path="/"
            element={
              <LayoutInformativo
                title={
                  <header>
                    <img
                      src={process.env.PUBLIC_URL + "/logo-justdf.png"}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </header>
                }
                subTitle={""}
                appBarItems={[]}
                footerTitle="Consulta Padrón"
                copyrightText="Dirección de Informática y Telecomunicaciones - Poder Judicial"
                // Descomentar esto para activar padrón
                indexItems={[
                  {
                    primaryText: "Inicio",
                    path: "/",
                  },
                  {
                    primaryText: "Consulta Padrón Definitivo",
                    path: "/elecciones/padron-definitivo",
                  },
                ]}
                contactItems={[
                  {
                    primaryText: "Consultas:",
                    secondaryText: "juzelectoral@justierradelfuego.gov.ar",
                    path: "",
                    icon: <EmailIcon />,
                  },
                  {
                    primaryText: "Reclamos:",
                    secondaryText: "reclamospadron@justierradelfuego.gov.ar",
                    path: "",
                    icon: <EmailIcon />,
                  },
                ]}
              />
            }
          >
            <Route index element={<Homepage />} />

            {/* Descomentar esto para activar padrón */}
            {appComponents.map((ruta, idx) => (
              <Route key={idx} path={ruta.path} element={ruta.element} />
            ))}

            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
        <Toaster />
      </Box>
    </QueryClientProvider>
  );
}

export default App;
