import { appRoutes } from "./appRoutes";

import ListPadronProvisorioPage from "./pages/ListPadronProvisorioPage/ListPadronProvisorioPage";
import ListPadronDefinitivoPage from "./pages/ListPadronDefinitivoPage/ListPadronDefinitivoPage";

export const appComponents = [
  {
    path: appRoutes.inicio,
    element: <div></div>,
  },
  {
    path: appRoutes.padronProvisorio,
    element: <ListPadronProvisorioPage />,
  },
  {
    path: appRoutes.padronDefinitivo,
    element: <ListPadronDefinitivoPage />,
  },
];
