import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import { PadronDefinitivoInterface } from "../../interfaces/PadronDefinitivoInterface";
import { useReactQuery } from "stj-components";
import { PadronProvisorioRequest } from "./padronProvisorio";

const { usePost } = useReactQuery;

// export const useGetPadronDefinitivo = (
//   eleccion_id: string,
//   documento: string,
//   queryParams?: any
// ) =>
//   useFetch<PadronDefinitivoInterface>(
//     pathToUrl(apiRoutes.consultaPadronDefinitivo, {
//       eleccion_id: eleccion_id,
//     }),
//     queryParams,
//     {
//       enabled: eleccion_id !== "0" && documento !== "0",
//     }
//   );

export const useGetPadronDefinitivo = (queryParams?: any) =>
  usePost<PadronDefinitivoInterface, PadronProvisorioRequest>(
    pathToUrl(apiRoutes.consultaPadronDefinitivoExterno),
    queryParams
  );
