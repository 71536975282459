import { EleccionInterface } from "../../interfaces/EleccionInterface";
import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { useFetch } = useReactQuery;

export const useGetElecciones = (queryParams: any) =>
  useFetch<EleccionInterface[]>(
    pathToUrl(apiRoutes.elecciones, {}, queryParams)
  );

export const useGetEleccionById = (id: number, queryParams: any) =>
  useFetch<EleccionInterface>(
    pathToUrl(apiRoutes.eleccionById, { id }),
    queryParams,
    {
      enabled: id !== 0,
    }
  );

export const useGetEleccioneVigente = () =>
  useFetch<EleccionInterface>(apiRoutes.eleccionVigente);
