import { PadronProvisorioInterface } from "../../interfaces/PadronProvisorioInterface";
import { EleccionInterface } from "../../interfaces/EleccionInterface";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import CardPersona from "./CardPersona";
import { Button } from "@mui/material";
import { PrintOutlined } from "@mui/icons-material";

interface PrintCardButtonProps {
  padronProvisorio: PadronProvisorioInterface | undefined;
  eleccion: EleccionInterface | undefined;
  isLoading: boolean;
  error: any;
}

const PrintCardProvisorioButton = (props: PrintCardButtonProps) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: `padron_definitivo_${props.padronProvisorio?.persona?.apellido}_${props.padronProvisorio?.persona?.nombre}`,
    pageStyle: `@media print {
      @page {
        margin-top: 250px;
      }
    }`,
    content: () => componentRef.current,
  });

  return (
    <div style={{ textAlign: "center" }}>
      <CardPersona ref={componentRef} {...props} />
      {props.padronProvisorio && (
        <Button
          startIcon={<PrintOutlined />}
          onClick={handlePrint}
          variant="contained"
          sx={{ mt: 1, minWidth: "196px" }}
        >
          Imprimir
        </Button>
      )}
    </div>
  );
};

export default PrintCardProvisorioButton;
