import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetPadronDefinitivo } from "../../services/api/padronDefinitivo";
import SearchPadronDefinitivo from "./Forms/SearchPadronDefinitivo";
import axios, { AxiosError } from "axios";
import { useGetEleccionById } from "../../services/api/elecciones";
import { SimpleCard } from "stj-components";
import PrintCardButton from "./PrintCardDefinitivoButton";
import { Alert, Button, Grid, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PadronDefinitivoInterface } from "../../interfaces/PadronDefinitivoInterface";
import { EleccionInterface } from "@interfaces/EleccionInterface";

const ListPadronDefinitivo = () => {
  const [mostrarBusqueda, setMostrarBusqueda] = useState(true);
  const [persona, setPersona] = useState<PadronDefinitivoInterface>();

  const { mutateAsync, error, isError, isLoading } = useGetPadronDefinitivo();

  const [eleccion, setEleccion] = useState<EleccionInterface>();

  const handleOnSubmit = async (values: any, refetch: any) => {
    const { eleccion } = values;

    setEleccion(eleccion);

    try {
      setMostrarBusqueda(false);
      await mutateAsync(values, {
        onSettled: (data) => {
          setPersona(data?.data);
          refetch();
        },
      });
    } catch (error) {
      refetch();
    }
  };

  useEffect(() => {
    if (isError) {
      if (axios.isAxiosError(error)) {
        const myerror = error as AxiosError<any, any>;
        toast.error(`${myerror.response?.data.message}`);
      }
    }
  }, [isError, error]);

  return (
    <>
      <SimpleCard title="Consulta Padrón Definitivo">
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          {mostrarBusqueda ? (
            <>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <SearchPadronDefinitivo handleOnSubmit={handleOnSubmit} />
              </Grid>
              <Alert severity="info">
                Rellene los campos y presione BUSCAR
              </Alert>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <PrintCardButton
                  persona={persona}
                  eleccion={eleccion}
                  isLoading={isLoading}
                  error={error}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  onClick={() => setMostrarBusqueda(true)}
                >
                  Hacer otra consulta
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </SimpleCard>
    </>
  );
};

export default ListPadronDefinitivo;
