import { PadronDefinitivoInterface } from "../../interfaces/PadronDefinitivoInterface";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Alert,
  CircularProgress,
  AlertTitle,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { forwardRef } from "react";
import { EleccionInterface } from "@interfaces/EleccionInterface";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";

interface CardPersonaProps {
  persona: PadronDefinitivoInterface | undefined;
  eleccion: EleccionInterface | undefined;
  isLoading: boolean;
  error: any;
}

const ESTADO_INHABILITADO = 2;
const CardPersona = forwardRef<HTMLDivElement, CardPersonaProps>(
  (props: CardPersonaProps, ref) => {
    const { persona, eleccion, isLoading, error } = props;

    return (
      <div ref={ref} style={{ display: "flex", justifyContent: "center" }}>
        {persona &&
        persona.estado_id !== ESTADO_INHABILITADO &&
        !isLoading &&
        eleccion ? (
          <Card sx={{ minWidth: 275, maxWidth: 600 }}>
            <CardHeader
              sx={{ textAlign: "center" }}
              title={<>{`${eleccion?.descripcion} `}</>}
              subheader={`Padrón Definitivo`}
            />
            <CardContent>
              <Typography textAlign="left">
                <b>
                  {eleccion?.segunda_vuelta
                    ? "Primera Vuelta:"
                    : "Fecha elección:"}
                </b>
                {` ${new Date(
                  `${eleccion?.primera_vuelta} ${"GMT-3"}`
                ).toLocaleString("es-AR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}`}
              </Typography>

              {eleccion?.segunda_vuelta && (
                <Typography textAlign="left" sx={{ mb: 1 }}>
                  <b>Segunda Vuelta:</b>
                  {` ${new Date(
                    `${eleccion?.segunda_vuelta} ${"GMT-3"}`
                  ).toLocaleString("es-AR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}`}
                </Typography>
              )}

              <Typography textAlign="left">
                <b>Jueza interina:</b>
                {` Dra. Mariel J. Zanini`}
              </Typography>
              <Typography textAlign="left">
                <b>Secretaria P.S.L.:</b>
                {` Dra. Cintia Ferreyra`}
              </Typography>

              <hr style={{ border: "solid 1px #bbb" }} />

              <Typography variant="h6" textAlign="left">
                <b>{`${persona?.apellido}, ${persona?.nombre} `}</b>
              </Typography>
              <Typography textAlign="left">
                <b>DNI:</b>
                {` ${persona?.documento} `}
              </Typography>
              <Typography textAlign="left">
                <b>Localidad:</b>
                {` ${persona?.localidad} `}
              </Typography>
              <Typography textAlign="left">
                <b>Domicilio:</b>
                {` ${persona?.domicilio} `}
              </Typography>

              <Typography variant="h6" sx={{ mt: 1 }} textAlign="left">
                Vota en:
              </Typography>

              {persona.eleccion_establecimiento && (
                <Typography textAlign="left">
                  <b>Establecimiento:</b>
                  {` ${
                    persona.eleccion_establecimiento !== null
                      ? persona?.eleccion_establecimiento?.establecimiento
                          ?.descripcion
                      : ""
                  }`}
                </Typography>
              )}

              {persona.lugar_votacion_mod && (
                <Typography textAlign="left">
                  <b>Lugar:</b>
                  {` ${
                    persona.lugar_votacion_mod !== null
                      ? persona?.lugar_votacion_mod?.descripcion
                      : ""
                  }`}
                </Typography>
              )}

              <Typography textAlign="left">
                <b>Dirección:</b>

                {persona.eleccion_establecimiento && (
                  <>
                    {
                      persona?.eleccion_establecimiento?.establecimiento
                        ?.observaciones
                    }
                  </>
                )}

                {persona.lugar_votacion_mod && (
                  <>{` ${persona?.lugar_votacion_mod?.calle?.descripcion} ${persona?.lugar_votacion_mod?.numero}`}</>
                )}
                {/* {` ${
                  persona.eleccion_establecimiento !== null
                    ? persona?.eleccion_establecimiento?.establecimiento
                        ?.observaciones
                    : ""
                }`} */}
              </Typography>

              {persona.circuito_mod && (
                <Typography textAlign="left">
                  <b>Circuito:</b>
                  {` ${
                    persona?.circuito_mod
                      ? persona?.circuito_mod
                      : persona?.circuito ?? ""
                  } `}
                </Typography>
              )}
              <Typography textAlign="left">
                <b>Sección:</b>
                {` ${persona?.seccion ?? ""} `}
              </Typography>
              <Typography textAlign="left">
                <b>Mesa:</b>
                {` ${
                  persona?.mesa_mod ? persona?.mesa_mod : persona?.mesa ?? ""
                } `}
              </Typography>
              <Typography textAlign="left">
                <b>Orden:</b>
                {` ${
                  persona?.orden_mod ? persona?.orden_mod : persona?.orden ?? ""
                } `}
              </Typography>
            </CardContent>
            {/* <Box
              sx={{
                width: "100%",
                height: "auto",
                minHeight: 287,
                maxHeight: 400,
              }}
            >
              <img
                src={`/establecimientos/${persona?.eleccion_establecimiento?.establecimiento?.id}.webp`}
                alt={`Establecimiento: ${persona?.eleccion_establecimiento?.establecimiento?.descripcion}`}
                style={{ width: "100%", height: "auto" }}
              />
            </Box> */}
            {persona?.eleccion_establecimiento?.establecimiento && (
              <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => zoomIn()}
                        size="large"
                      >
                        <ZoomInIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => zoomOut()}
                        size="large"
                      >
                        <ZoomOutIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => resetTransform()}
                        size="large"
                      >
                        <ZoomOutMapIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                    <TransformComponent>
                      <img
                        src={`/establecimientos/${persona?.eleccion_establecimiento?.establecimiento?.id}.webp`}
                        alt={`Establecimiento: ${persona?.eleccion_establecimiento?.establecimiento?.descripcion}`}
                        width={"100%"}
                        height={"100%"}
                      />
                    </TransformComponent>
                  </div>
                )}
              </TransformWrapper>
            )}
          </Card>
        ) : (
          <div>
            {isLoading ? (
              <CircularProgress />
            ) : persona?.estado_id === ESTADO_INHABILITADO ||
              (error && error?.response?.status === 404) ? (
              <Alert severity="error" title="404">
                <AlertTitle>
                  No se encuentra el documento en el padrón
                </AlertTitle>
                Verifique que escribió su documento correctamente e intente
                nuevamente.
              </Alert>
            ) : (
              <Alert severity="error" title="500">
                <AlertTitle>
                  El captcha no coincide, <b>expiró</b>, u ocurrió otro error en
                  el servidor
                </AlertTitle>
                Por favor, intente nuevamente.
              </Alert>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default CardPersona;
