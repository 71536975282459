// const baseUrlApiPersona = process.env.REACT_APP_API_URL_PERSONAS;
const baseUrlApiCaptcha = process.env.REACT_APP_URL;

export const apiRoutes = {
  // Hay que agregarle "http://", al llamarlo, porque no funciona sino
  persona: `personas`,
  personaById: `personas/:id`,

  elecciones: "elecciones",
  eleccionById: "elecciones/:id",
  eleccionVigente: "elecciones/vigente",

  // captcha: `${baseUrlApiCaptcha}/captcha/api/math`,
  captcha: `${baseUrlApiCaptcha}/captcha/api`,
  circuitos: "circuitos",
  circuitoById: "circuitos/:id",

  consultaPadronProvisorio: "padron-provisorio/eleccion",
  consultaPadronProvisorioExterno: "padron-provisorio",
  consultaPadronDefinitivo: "padron-definitivo/eleccion/:eleccion_id",
  consultaPadronDefinitivoExterno: "padron-definitivo",
};
