import React from 'react';
import { Form } from 'formik';
import { styled } from '@mui/material/styles';

const StyledForm = styled(Form)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    margin: "0px 50px",
  },
  [theme.breakpoints.up('md')]: {
    margin: "0px 200px",
  },
  [theme.breakpoints.up('lg')]: {
    margin: "0px 400px",
  },
  [theme.breakpoints.up('xl')]: {
    margin: "0px 500px",
  },
}));

export default StyledForm;