import {
  Alert,
  AlertTitle,
  CircularProgress,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardRow } from "stj-components";
import { appRoutes } from "src/appRoutes";
import { useGetEleccioneVigente } from "../../services/api/elecciones";

const HomePage = () => {
  const navigate = useNavigate();

  const {
    data: eleccionVigente,
    error,
    isError,
    isLoading,
  } = useGetEleccioneVigente();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      spacing={2}
    >
      <Grid
        item
        container
        display="flex"
        direction="column"
        justifyContent="center"
        textAlign="center"
        sx={{ height: "50vh" }}
      >
        <Collapse
          in={checked}
          {...(checked ? { timeout: 1000 } : {})}
          collapsedSize={50}
        >
          <Typography
            variant={"h3"}
            component={"h1"}
            align="center"
            sx={{ color: "black", fontSize: "4rem" }}
          >
            <b>Juzgado Electoral</b>
          </Typography>

          <Typography
            variant={"h3"}
            component={"h2"}
            align="center"
            sx={{ color: "black", fontSize: "3rem" }}
          >
            {/* Consulta de Padrón.  */}
            {/* La aplicación permanecerá inactiva hasta la próxima elección. */}
          </Typography>
          <br></br>
        </Collapse>
      </Grid>

      {isLoading && <CircularProgress />}

      {isError && (
        <Alert severity="error">
          <AlertTitle>No se encontró elección vigente</AlertTitle>
        </Alert>
      )}

      {!isLoading &&
        !isError &&
        !eleccionVigente?.fecha_padron_definitivo &&
        !eleccionVigente?.fecha_padron_provisorio && (
          <Alert severity="info">
            <AlertTitle>
              Todavía no se han generados padrones para esta elección.
            </AlertTitle>
          </Alert>
        )}

      {!isLoading && !isError && eleccionVigente && (
        <Grid
          item
          xs={12}
          display="flex"
          textAlign="center"
          justifyContent="center"
        >
          {/* Descomentar esto para activar padrón */}
          {eleccionVigente?.fecha_padron_definitivo && (
            <CardRow
              cards={[
                {
                  onClick: () => navigate(appRoutes.padronDefinitivo),
                  title: "Padrón Definitivo",
                  text: "Consultar padrón definitivo",
                  icon: <PeopleIcon fontSize="large" />,
                },
              ]}
            />
          )}

          {!eleccionVigente?.fecha_padron_definitivo &&
            eleccionVigente?.fecha_padron_provisorio && (
              <CardRow
                cards={[
                  {
                    onClick: () => navigate(appRoutes.padronProvisorio),
                    title: "Padrón Provisorio",
                    text: "Consultar padrón provisorio",
                    icon: <PeopleIcon fontSize="large" />,
                  },
                ]}
              />
            )}
        </Grid>
      )}
    </Grid>
  );
};

export default HomePage;
