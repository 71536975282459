import { PadronProvisorioInterface } from "../../interfaces/PadronProvisorioInterface";
import { EleccionInterface } from "../../interfaces/EleccionInterface";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Alert,
  CircularProgress,
  AlertTitle,
} from "@mui/material";
import React, { forwardRef } from "react";

interface CardPersonaProps {
  padronProvisorio: PadronProvisorioInterface | undefined;
  eleccion: EleccionInterface | undefined;
  isLoading: boolean;
  error: any;
}

const ESTADO_INHABILITADO = 2;
const CardPersona = forwardRef<HTMLDivElement, CardPersonaProps>(
  (props: CardPersonaProps, ref) => {
    const { padronProvisorio, eleccion, isLoading, error } = props;

    return (
      <div ref={ref} style={{ display: "flex", justifyContent: "center" }}>
        {/* TODO: Hay que quitar a las personas con ESTADO_INHABILITADO */}
        {padronProvisorio ? (
          <>
            <Card sx={{ minWidth: 275, maxWidth: 500 }}>
              <CardHeader
                sx={{ textAlign: "center" }}
                title={<>{`${eleccion?.descripcion} `}</>}
                subheader={`Padrón Provisorio`}
              />
              <CardContent>
                <Typography textAlign="left">
                  <b>
                    {eleccion?.segunda_vuelta
                      ? "Primera Vuelta:"
                      : "Fecha elección:"}
                  </b>
                  {` ${new Date(
                    `${eleccion?.primera_vuelta} ${"GMT-3"}`
                  ).toLocaleString("es-AR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}`}
                </Typography>
                {eleccion?.segunda_vuelta && (
                  <Typography textAlign="left" sx={{ mb: 1 }}>
                    <b>Segunda Vuelta:</b>
                    {` ${new Date(
                      `${eleccion?.segunda_vuelta} ${"GMT-3"}`
                    ).toLocaleString("es-AR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}`}
                  </Typography>
                )}
                <Typography textAlign="left">
                  <b>Jueza interina:</b>
                  {` Dra. Mariel J. Zanini`}
                </Typography>
                <Typography textAlign="left">
                  <b>Secretaria P.S.L.:</b>
                  {` Dra. Cintia Ferreyra`}
                </Typography>

                <hr style={{ border: "solid 1px #bbb" }} />
                <Typography
                  textAlign="left"
                  variant="h6"
                >{`${padronProvisorio.persona?.apellido}, ${padronProvisorio.persona?.nombre} `}</Typography>
                <Typography textAlign="left">
                  <b>DNI:</b>
                  {` ${padronProvisorio.persona?.documento} `}
                </Typography>
                <Typography textAlign="left">
                  <b>Localidad:</b>
                  {` ${padronProvisorio.persona?.domicilio_vigente?.localidad?.descripcion} `}
                </Typography>
                <Typography textAlign="left">
                  <b>Domicilio: </b>
                  {
                    padronProvisorio.persona?.domicilio_vigente?.calle
                      ?.descripcion
                  }
                  {padronProvisorio.persona?.domicilio_vigente?.numero
                    ? ` - Nro: ${padronProvisorio.persona?.domicilio_vigente?.numero}`
                    : ""}
                  {padronProvisorio.persona?.domicilio_vigente?.observaciones
                    ? `- ${padronProvisorio.persona?.domicilio_vigente?.observaciones}`
                    : ""}
                </Typography>
              </CardContent>
            </Card>
          </>
        ) : (
          <div>
            {isLoading ? (
              <CircularProgress />
            ) : error && error.response.status === 404 ? (
              <Alert severity="error" title="404">
                <AlertTitle>404</AlertTitle>
                No se encuentra el documento en el Padrón Provisorio.
              </Alert>
            ) : (
              <Alert severity="error" title="500">
                <AlertTitle>500</AlertTitle>
                El captcha no coincide, <b>expiró</b>, u ocurrió otro error en
                el servidor.
              </Alert>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default CardPersona;
