import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { FormButton, TextInput, SelectInput } from "stj-components";
import * as Yup from "yup";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useGetElecciones } from "../../../services/api/elecciones";
import { useGetCaptcha } from "../../../services/api/captcha";
import { Button, CircularProgress } from "@mui/material";
import { ReplayOutlined } from "@mui/icons-material";
import StyledForm from "../../../components/crud/StyledForm";
import { useEffect } from "react";

const initialValues = {
  documento: "",
  eleccion_id: "",
  captcha: "",
};

interface SearchPersonaProps {
  handleOnSubmit: (data: Array<any>, refetch: any) => void;
}
const SearchPadronDefinitivo = (props: SearchPersonaProps) => {
  const { handleOnSubmit } = props;

  //-- Cargo datos de las elecciones
  const { data: elecciones } = useGetElecciones({
    sort: "-primera_vuelta",
  });

  const {
    data: captcha,
    isLoading,
    isRefetching,
    refetch,
  } = useGetCaptcha({
    enabled: false,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        eleccion_id:
          elecciones && elecciones?.length > 0
            ? elecciones[0].id.toString()
            : "",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        documento: Yup.string()
          .matches(
            /^[0-9]+$/,
            "El documento debe ser numérico. No debe contener puntos ni espacios, ni guiones."
          )
          .required("Se requiere un documento"),
        eleccion_id: Yup.string().required("Se requiere una elección"),
        captcha: Yup.string().required("Se requiere completar el captcha"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const valores = {
          ...values,
          key: captcha?.key,
          eleccion: elecciones?.filter(
            (eleccion) => eleccion.id === parseInt(values.eleccion_id)
          )[0],
        };
        handleOnSubmit(valores as any, refetch);
        setSubmitting(false);
      }}
    >
      {(formik) => (
        <StyledForm>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {/* Fila superior */}
            <Grid item xs={12}>
              <TextInput
                label="DNI"
                name="documento"
                type="text"
                placeholder="N° documento"
                size="small"
                fullWidth
                autoFocus={true}
              />
            </Grid>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} justifyContent="center">
                <SelectInput
                  label="Elecciones"
                  options={
                    elecciones?.map((eleccion) => ({
                      label: eleccion.descripcion,
                      value: eleccion.id,
                    })) ?? []
                  }
                  name="eleccion_id"
                  readOnly={true}
                />
              </Grid>
              {captcha && !isLoading && !isRefetching ? (
                <>
                  <Grid item xs={8}>
                    <div style={{ height: "50px" }}>
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          border: "1px solid #9e9e9e",
                          borderRadius: "5%",
                        }}
                        src={captcha.img}
                        alt="imagen de validación captcha"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      sx={{ height: "50px" }}
                      color="info"
                      aria-label="recargar captcha"
                      size="large"
                      variant="contained"
                      fullWidth
                      onClick={() => refetch()}
                    >
                      <ReplayOutlined />
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={6}>
                  <CircularProgress />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextInput
                  label="Respuesta"
                  name="captcha"
                  type="text"
                  placeholder="Ingrese la respuesta"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} alignItems="right">
                <FormButton
                  text="Buscar"
                  type="submit"
                  variant="contained"
                  startIcon={<SearchOutlinedIcon />}
                  color="secondary"
                />
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      )}
    </Formik>
  );
};

export default SearchPadronDefinitivo;
