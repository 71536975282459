import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import { PadronProvisorioInterface } from "../../interfaces/PadronProvisorioInterface";
import { useReactQuery } from "stj-components";

const { usePost } = useReactQuery;

export interface PadronProvisorioRequest {
  eleccion_id: string;
  documento: string;
  key: string;
  captcha: string;
}

export const useGetPadronProvisorio = (queryParams?: any) =>
  usePost<PadronProvisorioInterface, PadronProvisorioRequest>(
    pathToUrl(apiRoutes.consultaPadronProvisorioExterno),
    queryParams
  );
